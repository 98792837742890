<template>
  <div class="container">

    <b-form @submit.prevent="onSubmit">
      <h3>Установка пароля</h3>

      <b-form-group id="email" label-for="email" label="Email">
        <b-form-input id="email" type="text" placeholder="" required v-model="doctor.email" autocomplete="off"></b-form-input>
      </b-form-group>
      <b-form-group id="password" label-for="password" label="Пароль" invalid-feedback="Пароль должен быть от 8 до 32 символов">
        <b-form-input id="password" type="password" required placeholder="Введите пароль" v-model="doctor.password" :state="doctor.password && doctor.password.length > 7" autocomplete="off"></b-form-input>
      </b-form-group>
      <b-form-group id="passwordRepeat" label-for="passwordRepeat" label="Повторите пароль" invalid-feedback="Пароли не совпадают">
        <b-form-input id="passwordRepeat" type="password" required placeholder="Введите пароль" v-model="doctor.passwordRepeat"  :state="doctor.passwordRepeat && doctor.password === doctor.passwordRepeat" autocomplete="off"></b-form-input>
      </b-form-group>
      <b-form-checkbox id="personalDataAccept" name="personalDataAccept" v-model="doctor.acceptPersonalData" :state="doctor.acceptPersonalData">
        Согласен с политикой в отношении персональных данных
        <b-form-invalid-feedback :state="doctor.acceptPersonalData">Необходимо дать согласие с политикой в отношении персональных данных</b-form-invalid-feedback>
      </b-form-checkbox>



      <b-button :disabled="saving" variant="info" type="submit" >Установить пароль<b-spinner v-if="saving" variant="light" small></b-spinner></b-button>
    </b-form>

    <b-modal id="clinicModal" hide-footer size="lg" title="Успешная регистрация" @close="closeModal()" no-close-on-backdrop no-close-on-esc >
      <div>
        Пароль успешно установлен. Сейчас будет произведен вход в кабинет.
      </div>

    </b-modal>
  </div>
</template>

<script>
import {host, roles} from "@/constants";

export default {
  props:["urlParam"],
  name: "PatientLinkSetPassword",

  data() {
    return {
      saving:false,
      regionOptions: [],
      clinicOptions: [],
      specializationOptions: [],
      clinicId: null,
      specId: null,
      doctor:  {
        firstname: '',
        middlename: '',
        lastname: '',
        genderCode: 1,
        regionCode: 6611,
        password: null,
        passwordRepeat: null,
        phoneNumber: '',
        email: '',
        doctorComment: '',
        token: null,
        specs: [],
        clinicId: null,
        acceptPersonalData: null
      },

    }
  },
  methods: {

    closeModal() {
      fetch(host + '/rest/session', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({login: this.doctor.email, password: this.doctor.password}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              this.isAuthorized = true
              this.role = json.role
              this.rolename = roles.filter(t => t.id == this.role)[0].name
              this.username = json.username
              this.userId = json.userId

              if (this.role == 3) {
                this.$router.push('/analits')
              }  else if (this.role == 2) {
                this.$router.push('/patients')
              }  else {
                this.$router.push('/patients/' + this.userId)
              }
            } else {
              this.$errorToast(json.message);
            }
            this.saving = false
          }).catch(error => {
        this.$errorToast(error);
        this.saving = false
      })
    },
    async onSubmit() {
      if (!this.doctor.acceptPersonalData) {
        this.doctor.acceptPersonalData = false
        //this.$errorToast('Необходимо дать согласие с политикой в отношении персональных данных')
        return
      }


      this.saving = true
      let method = 'POST'

      let rs = await fetch(host + '/rest/register/password', {
        method: method,
        credentials: 'include',
        body: JSON.stringify({urlParam: this.urlParam, email: this.doctor.email, pwd: this.doctor.password, acceptPersonalData: this.doctor.acceptPersonalData}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await rs.json()

      if (json.resultCode != 0) {
        this.$errorToast(json.resultCode + ' ' + json.message)
        this.saving = false
        return
      } else {
        this.$bvModal.show('clinicModal')
      }

      // let localDocId = this.doctorId
      // if (!this.doctorId) {
      //   localDocId = json.entityId
      // }
      // json = await this.updateDoctorClinics(localDocId)
      // if(json) {
      //   this.loading = false
      //   return
      // }
      // json = await this.updateDoctorSpecializations(localDocId)
      // if(json) {
      //   this.saving = false
      //   return
      // }
      this.saving = false
      //this.$router.push('/doctors')

    },
    checkURL() {
      fetch(host + '/rest/register/check/' + this.urlParam, {
        method: 'GET',
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            console.log(JSON.stringify(json))
            if (json.resultCode !== 0) {
              this.$router.push("/")
            }
            this.doctor.email = json.email
          })
          .catch(error => {
            console.log(error)
            this.$router.push("/")
          })


    }
  },
  mounted() {

    this.checkURL()
  }
}

</script>

<style scoped>

</style>